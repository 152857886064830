import React, { useState, useEffect } from 'react';
import {
  Box, Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, Typography,
  Avatar, IconButton, Button, Badge
} from '@mui/material';
import { Dashboard, Person, AccountBalance, DirectionsBus, Schedule, Receipt, LocalShipping, ListAlt, BarChart, Menu, Home as HomeIcon } from '@mui/icons-material';
import EventIcon from '@mui/icons-material/Event';
import Work from '@mui/icons-material/Work'; 
import { Link, useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import axiosInstance from './axiosInstance'; // Assuming axiosInstance is already set up

const drawerWidth = 240;
const theme = createTheme();

const AdminPanel = ({ children }) => {
  const userId = localStorage.getItem('userId');
  const username = localStorage.getItem('username');
  const role = localStorage.getItem('role');

  const [mobileOpen, setMobileOpen] = useState(false);
  const [pendingExpensesCount, setPendingExpensesCount] = useState(0); 
  const [pendingCashCount, setPendingCashCount] = useState(0); 
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  useEffect(() => {
    // Fetch new record notifications and update counts
    const checkNewNotifications = async () => {
      try {
        const expenseResponse = await axiosInstance.get('/expense/pending');
        setPendingExpensesCount(expenseResponse.data.length); // Get the count of pending expenses

        const cashForwardResponse = await axiosInstance.get('/cashforward/pending');
        setPendingCashCount(cashForwardResponse.data.length); // Get the count of pending cash forwards
      } catch (error) {
        console.error('Error fetching new notifications', error);
      }
    };

    checkNewNotifications();
  }, []);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('role');
    localStorage.removeItem('username');
    localStorage.removeItem('userid');
    window.location.href = '/';
  };

  const drawer = (
    <Box>
      <Box sx={{ textAlign: 'center', padding: 2, background: '#1E1E2A' }}>
        <Avatar alt={username} src="/path/to/admin-image.jpg" sx={{ margin: '0 auto' }} />
        <Typography variant="h6" style={{ color: 'white', marginTop: 1 }}>{username}</Typography>
      </Box>
      <Divider />
      <List>
        <ListItem button component={Link} to="/admin/dashboard" selected={location.pathname === '/admin/dashboard'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <Dashboard />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItem>
        
        <ListItem button component={Link} to="/admin/reports" selected={location.pathname === '/admin/reports'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <Badge badgeContent={pendingCashCount} color="error" invisible={pendingCashCount === 0}>
              <AccountBalance />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Financial" />
        </ListItem>

        <ListItem button component={Link} to="/admin/add-user" selected={location.pathname === '/admin/add-user'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <Person />
          </ListItemIcon>
          <ListItemText primary="Users" />
        </ListItem>
        
        <ListItem button component={Link} to="/admin/manage-buses" selected={location.pathname === '/admin/manage-buses'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <DirectionsBus />
          </ListItemIcon>
          <ListItemText primary="Vehicles/Buses" />
        </ListItem>
        
        <ListItem button component={Link} to="/admin/corporate" selected={location.pathname === '/admin/corporate'}>
          <ListItemIcon sx={{ color: 'white' }}>
          <Work />
          </ListItemIcon>
          <ListItemText primary="Corporate" />
        </ListItem>
        
        <ListItem button component={Link} to="/admin/view-tickets" selected={location.pathname === '/admin/view-tickets'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <Receipt />
          </ListItemIcon>
          <ListItemText primary="View Tickets" />
        </ListItem>

        <ListItem button component={Link} to="/admin/expense" selected={location.pathname === '/admin/expense'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <Badge badgeContent={pendingExpensesCount} color="error" invisible={pendingExpensesCount === 0}>
              <EventIcon />
            </Badge>
          </ListItemIcon>
          <ListItemText primary="Expenses" />
        </ListItem>

        <ListItem button component={Link} to="/admin/parcels" selected={location.pathname === '/admin/parcels'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <LocalShipping />
          </ListItemIcon>
          <ListItemText primary="Parcels" />
        </ListItem>
        
        <ListItem button component={Link} to="/admin/office" selected={location.pathname === '/admin/office'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <ListAlt />
          </ListItemIcon>
          <ListItemText primary="Branch Status" />
        </ListItem>

        <ListItem button component={Link} to="/admin/view-reports" selected={location.pathname === '/admin/view-reports'}>
          <ListItemIcon sx={{ color: 'white' }}>
            <BarChart />
          </ListItemIcon>
          <ListItemText primary="Reports" />
        </ListItem>
      </List>
      <Button variant="contained" color="secondary" onClick={handleLogout}>
        Logout
      </Button>
    </Box>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <Box component="nav">
          {isMobile ? (
            <Drawer
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              sx={{
                '& .MuiDrawer-paper': {
                  boxSizing: 'border-box',
                  width: drawerWidth,
                  background: '#1E1E2C',
                  color: 'white',
                },
              }}
            >
              {drawer}
            </Drawer>
          ) : (
            <Drawer
              variant="permanent"
              sx={{
                width: drawerWidth,
                flexShrink: 0,
                '& .MuiDrawer-paper': {
                  width: drawerWidth,
                  boxSizing: 'border-box',
                  background: '#1E1E2C',
                  color: 'white',
                },
              }}
            >
              {drawer}
            </Drawer>
          )}
        </Box>
        <Box
          component="main"
          sx={{ flexGrow: 1, bgcolor: 'background.default', height: '100vh', overflow: 'auto' }}
        >
          <Box sx={{ padding: 2, bgcolor: 'blue', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {isMobile && (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                sx={{ mr: 2 }}
              >
                <Menu />
              </IconButton>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
              <Typography variant="h4" sx={{ color: 'white' }}>{`${role} Dashboard`}</Typography>
              <Box sx={{ flexGrow: 1 }} />
              <Link to="/home" style={{ color: 'white', textDecoration: 'none', display: 'flex', alignItems: 'center', marginLeft: 'auto' }}>
                <HomeIcon sx={{ marginRight: 1 }} />
                <Typography variant="h6" sx={{ color: 'white' }}>Home</Typography>
              </Link>
            </Box>
          </Box>
          <Divider />
          <Box sx={{ padding: 2 }}>{children}</Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default AdminPanel;
