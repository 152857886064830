import React from 'react';
import AdminPanel from './AdminPanel';
import { Route, Routes } from 'react-router-dom';
import { Box, Divider } from '@mui/material';
import ManageBuses from './ManageBuses';
import ViewReports from './ViewReports';
import ManageBusSchedules from './ManageBusSchedules';
import ViewTickets from './ViewTickets';
import DashboardPage from './DashboardPage';
import AdminParcels from './AdminParcels';
import FinancialReport from './FinancialReport';
import AddUserForm from './AddUserForm';
import Reports from './Reports'
import ExpenseTable from './ExpenseTable';
import CorporateManager from './CorporateManager';

const AdminDashboard = ({ role, username, userId }) => {
  return (
  <AdminPanel role={role} username={username} userId={userId}>
      <Box sx={{ padding: 0 }}>
        <Divider sx={{ marginBottom: 2 }} />
        <Routes>
          <Route path="dashboard" element={<DashboardPage />} />
          <Route path="reports" element={<Reports userId={userId} username={username} role={role} />} /> 
          <Route path="manage-buses" element={<ManageBuses />} />
          <Route path="add-user" element={<AddUserForm />} />
          <Route path="manage-schedule" element={<ManageBusSchedules />} />
          <Route path="view-reports" element={<ViewReports />} />
          <Route path="view-tickets" element={<ViewTickets />} />
          <Route path="parcels" element={<AdminParcels />} />
          <Route path="office" element={<FinancialReport />} />
          <Route path="expense" element={<ExpenseTable />} />
          <Route path="corporate" element={<CorporateManager />} />
        </Routes>
      </Box>
    </AdminPanel>
  );
};

export default AdminDashboard;
