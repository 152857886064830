import React, { useState } from 'react';
import axiosInstance from '../Admin/axiosInstance';
import jsPDF from 'jspdf';
import 'jspdf-autotable'; // For improved table formatting in the PDF
import {
  Container,
  TextField,
  Select,
  MenuItem,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
  CircularProgress,
  Alert,
} from '@mui/material';

const ParcelTable = () => {
  const [date, setDate] = useState('');
  const [direction, setDirection] = useState(1);
  const [parcels, setParcels] = useState([]);
  const [receivedParcels, setReceivedParcels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingReceived, setLoadingReceived] = useState(false);
  const [error, setError] = useState('');
  const [errorReceived, setErrorReceived] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [activeTable, setActiveTable] = useState(''); // Track which table is active ('loading' or 'received')

  // Fetch data from the endpoint
  const fetchLoadingListData = async () => {
    setActiveTable('loading');
    setLoading(true);
    setError('');
    setParcels([]);
    try {
      const response = await axiosInstance.get(`/parcel/loadinglist/${date}/${direction}`);
      if (response.data.length === 0) {
        setError('No parcels found for the selected date and route.');
      } else {
        setParcels(response.data);
      }
    } catch (error) {
      setError('Error fetching data. Please check the date and try again.');
    }
    setLoading(false);
  };

  // Fetch data for received parcels
  const fetchReceivedParcelsData = async () => {
    setActiveTable('received');
    setLoadingReceived(true);
    setErrorReceived('');
    setReceivedParcels([]); // Clear previous data
    try {
      const response = await axiosInstance.get(`/parcel/received/${date}`);
      console.log("Received parcels from API:", response.data); // Log the response
      if (response.data.length === 0) {
        setErrorReceived('No received parcels found for the selected date. Please try a different date.');
      } else {
        console.log("Setting received parcels:", response.data); // Log before setting
        setReceivedParcels(response.data); // Set new data
      }
    } catch (error) {
      setErrorReceived('Error fetching received parcels data. Please check the date and try again.');
    }
    setLoadingReceived(false);
  };
  

  // Generate a styled PDF file
  const generatePDF = (data) => {
    const doc = new jsPDF();
    doc.setFontSize(14);
    doc.text('Parcel Loading List', doc.internal.pageSize.getWidth() / 2, 18, { align: 'center' });
    doc.setFontSize(12);
    doc.text('Dream C', doc.internal.pageSize.getWidth() / 2, 25, { align: 'center' });
    doc.text('Headquarters: Nairobi, Kenya', doc.internal.pageSize.getWidth() / 2, 30, { align: 'center' });
    doc.text('Contact: +254 712 177 177', doc.internal.pageSize.getWidth() / 2, 35, { align: 'center' });

    const fileName = `Parcel_Report_${date}.pdf`;
    const generatedDate = new Date().toLocaleString();
    doc.text(`Generated on: ${generatedDate}`, doc.internal.pageSize.getWidth() / 2, 40, { align: 'center' });

    const tableData = data.map((parcel) => [
      parcel.source,
      parcel.destination,
      parcel.senderName,
      parcel.senderPhone,
      parcel.receiverName,
      parcel.receiverPhone,
      parcel.itemsCount,
      `${parcel.packageType}, ${parcel.parcelContent}`,
      parcel.trackingCode,
      (parcel.cashAmount + parcel.mpesaAmount).toFixed(2),
    ]);

    doc.autoTable({
      startY: 45,
      head: [['Source', 'Destination', 'Sender Name', 'Sender Phone', 'Receiver Name', 'Receiver Phone', 'Items Count	', 'Description', 'Tracking Code', 'Price (Ksh)']],
      body: tableData,
    });

    doc.save(fileName);
  };

  return (
    <Container>
      <Typography variant="h4" align="center" gutterBottom>
        Parcel Loading & Received List
      </Typography>

      {/* Form for Date Input */}
      <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
        <TextField
          label="Date"
          type="date"
          value={date}
          onChange={(e) => setDate(e.target.value)}
          variant="outlined"
          sx={{ marginRight: '10px', width: '200px' }}
          InputLabelProps={{
            shrink: true,
          }}
        />
        <Select
          value={direction}
          onChange={(e) => setDirection(e.target.value)}
          variant="outlined"
          sx={{ marginRight: '10px', width: '200px' }}
        >
          <MenuItem value={1}>Nairobi-Wajir</MenuItem>
          <MenuItem value={2}>Wajir-Nairobi</MenuItem>
        </Select>
        <Button variant="contained" color="primary" onClick={fetchLoadingListData} sx={{ marginRight: '10px' }}>
          Fetch Loading List
        </Button>
        <Button variant="contained" color="secondary" onClick={fetchReceivedParcelsData}>
          Fetch My Received Parcels
        </Button>
      </div>

      {/* Error Message */}
      {error && <Alert severity="error">{error}</Alert>}
      {errorReceived && <Alert severity="error">{errorReceived}</Alert>}

      {/* Loading Indicator */}
      {(loading || loadingReceived) && (
        <div style={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress color="primary" />
        </div>
      )}

      {/* Display only the active table */}
      {activeTable === 'loading' && parcels.length > 0 && (
        <>
          <ParcelTableComponent title="Parcel Loading List" parcels={parcels} rowsPerPage={rowsPerPage} page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} />
          <Button variant="contained" color="primary" onClick={() => generatePDF(parcels)} sx={{ marginTop: '20px' }} disabled={parcels.length === 0}>
            Download PDF
          </Button>
        </>
      )}

    {activeTable === 'received' && receivedParcels.length > 0 && (
    <>
        <ParcelTableComponent title="Received Parcels List" parcels={receivedParcels} rowsPerPage={rowsPerPage} page={page} setPage={setPage} setRowsPerPage={setRowsPerPage} />
        <Button variant="contained" color="primary" onClick={() => generatePDF(receivedParcels)} sx={{ marginTop: '20px' }} disabled={receivedParcels.length === 0}>
        Download PDF
        </Button>
    </>
    )}

    </Container>
  );
};

// Parcel Table Component to avoid repetition
const ParcelTableComponent = ({ title, parcels, rowsPerPage, page, setPage, setRowsPerPage }) => (
  <Paper sx={{ width: '100%', overflow: 'hidden', marginTop: '20px' }}>
    <Typography variant="h6" align="center" sx={{ paddingTop: '10px' }}>
      {title}
    </Typography>
    <TableContainer>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {['ID', 'Source', 'Destination', 'Sender Name', 'Sender Phone', 'Receiver Name', 'Receiver Phone', 'Item Count', 'Description', 'Tracking Code', 'Price (Ksh)'].map((column, index) => (
              <TableCell key={index} align="center" sx={{ backgroundColor: 'primary.main', color: 'white' }}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {parcels.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((parcel) => (
            <TableRow hover role="checkbox" tabIndex={-1} key={parcel.id}>
              <TableCell align="center">{parcel.id}</TableCell>
              <TableCell align="center">{parcel.source}</TableCell>
              <TableCell align="center">{parcel.destination}</TableCell>
              <TableCell align="center">{parcel.senderName}</TableCell>
              <TableCell align="center">{parcel.senderPhone}</TableCell>
              <TableCell align="center">{parcel.receiverName}</TableCell>
              <TableCell align="center">{parcel.receiverPhone}</TableCell>
              <TableCell align="center">{parcel.itemsCount}</TableCell>
              <TableCell align="center">{parcel.packageType}, {parcel.parcelContent} </TableCell>
              <TableCell align="center">{parcel.trackingCode}</TableCell>
              <TableCell align="center">{(parcel.cashAmount + parcel.mpesaAmount).toFixed(2)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={parcels.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={(event, newPage) => setPage(newPage)}
      onRowsPerPageChange={(event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      }}
    />
  </Paper>
);

export default ParcelTable;
