import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button, Menu, MenuItem, IconButton, useMediaQuery, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useTheme } from '@mui/material/styles';
import logoImage from '../images/home1.png';

const Navbar = ({ onLogout }) => {
  const user = localStorage.getItem('username');
  const role = localStorage.getItem('role');
  const place = localStorage.getItem('place');
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const location = useLocation(); // Get the current location

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const isActive = (path) => {
    return location.pathname === path ? { backgroundColor: '#0066cc', color: 'white' } : {};
  };

  const drawer = (
    <div
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {user ? (
          <>
            <ListItem button component={Link} to="/home" style={isActive('/home')}>
              <ListItemText primary="Home" />
            </ListItem>
            <ListItem button component={Link} to="/ticket-status" style={isActive('/ticket-status')}>
              <ListItemText primary="Ticket Status" />
            </ListItem>
            <ListItem button component={Link} to="/parcel-status" style={isActive('/parcel-status')}>
              <ListItemText primary="Parcel Status" />
            </ListItem>
            <ListItem button component={Link} to="/contact-us" style={isActive('/contact-us')}>
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem button component={Link} to="/about-us" style={isActive('/about-us')}>
              <ListItemText primary="About Us" />
            </ListItem>
            {role === 'ADMIN' && (
              <ListItem button component={Link} to="/admin/dashboard" style={isActive('/admin/dashboard')}>
                <ListItemText primary="Dashboard" />
              </ListItem>
            )}
            <ListItem button onClick={onLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          </>
        ) : (
          <>
            <ListItem button component={Link} to="/contact-us" style={isActive('/contact-us')}>
              <ListItemText primary="Contact Us" />
            </ListItem>
            <ListItem button component={Link} to="/about-us" style={isActive('/about-us')}>
              <ListItemText primary="About Us" />
            </ListItem>
            <ListItem button component={Link} to="/home" style={isActive('/home')}>
              <ListItemText primary="Login" />
            </ListItem>
          </>
        )}
      </List>
    </div>
  );

  return (
    <AppBar position="static" sx={{ bgcolor: '#0080F' }}>
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="menu" sx={{ mr: 2 }} onClick={toggleDrawer(true)}>
          <MenuIcon />
        </IconButton>
        <Drawer anchor="left" open={drawerOpen} onClose={toggleDrawer(false)}>
          {drawer}
        </Drawer>
        <Typography variant="h6" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', color: 'white' }}>
          <img src={logoImage} alt="Logo" style={{ height: 40, marginRight: 10 }} />
          {!isMobile && user && `Welcome ${user} ${place}`}
        </Typography>

        {!isMobile && (
          <>
            {user ? (
              <>
                <Button style={isActive('/home')} color="inherit">
                  <Link to="/home" style={{ color: 'white', textDecoration: 'none' }}>Home</Link>
                </Button>
                <Button color="inherit" onClick={handleMenu}>
                  Services
                </Button>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={handleClose}>
                    <Button component={Link} to="/ticket-status" style={isActive('/ticket-status')}>
                      Ticket Status
                    </Button>
                  </MenuItem>
                  <MenuItem onClick={handleClose}>
                    <Button component={Link} to="/parcel-status" style={isActive('/parcel-status')}>
                      Parcel Status
                    </Button>
                  </MenuItem>
                </Menu>
                <Button style={isActive('/contact-us')} color="inherit">
                  <Link to="/contact-us" style={{ color: 'white', textDecoration: 'none' }}>Contact Us</Link>
                </Button>
                <Button style={isActive('/about-us')} color="inherit">
                  <Link to="/about-us" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
                </Button>
                {role === 'ADMIN' && (
                  <Button style={isActive('/admin/dashboard')} color="inherit">
                    <Link to="/admin/dashboard" style={{ color: 'white', textDecoration: 'none' }}>Dashboard</Link>
                  </Button>
                )}
                <Button color="inherit" onClick={onLogout} style={{ marginLeft: '10px' }}>
                  Logout
                </Button>
              </>
            ) : (
              <>
                <Button style={isActive('/contact-us')} color="inherit">
                  <Link to="/contact-us" style={{ color: 'white', textDecoration: 'none' }}>Contact Us</Link>
                </Button>
                <Button style={isActive('/about-us')} color="inherit">
                  <Link to="/about-us" style={{ color: 'white', textDecoration: 'none' }}>About Us</Link>
                </Button>
                <Button style={isActive('/')} color="inherit">
                  <Link to="/login" style={{ color: 'white', textDecoration: 'none' }}>Login</Link>
                </Button>
              </>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
