import React, { useState, useEffect } from 'react';
import axiosInstance from '../Admin/axiosInstance';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Container, TextField, Select, MenuItem, Button, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Grid, Alert, CircularProgress, TablePagination } from '@mui/material';
import { Schedule } from '@mui/icons-material';
import './../../App.css'; 


const WaybillComponent = () => {
  const [date, setDate] = useState('');
  const [route, setRoute] = useState('1'); // Default to Nairobi-Wajir
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [busOptions, setBusOptions] = useState([]);
  const [driverOptions, setDriverOptions] = useState([]);
  const [selectedBus, setSelectedBus] = useState('');
  const [selectedDriver, setSelectedDriver] = useState('');
  const [selectedBusDetails, setSelectedBusDetails] = useState(null);
  const [selectedDriverDetails, setSelectedDriverDetails] = useState(null);
  const [fetchingData, setFetchingData] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  // Fetch waybill data
  const fetchWaybillData = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await axiosInstance.get(`/parcel/waybill/${date}/${route}`);
      if (response.data.length === 0) {
        setError('No data found for the selected date and route.');
        setData([]);
      } else {
        setData(response.data);
      }
    } catch (error) {
      setError('Error fetching waybill data. Please try again.');
      console.error('Error fetching waybill data:', error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch buses and drivers
  useEffect(() => {
    const fetchBuses = async () => {
      setFetchingData(true);
      try {
        const response = await axiosInstance.get('/bus/viewAll');
        setBusOptions(response.data); // Assuming response.data is an array of buses
      } catch (error) {
        console.error('Error fetching buses:', error);
      } finally {
        setFetchingData(false);
      }
    };

    const fetchDrivers = async () => {
      setFetchingData(true);
      try {
        const response = await axiosInstance.get('/users/drivers');
        setDriverOptions(response.data); // Assuming response.data is an array of drivers
      } catch (error) {
        console.error('Error fetching drivers:', error);
      } finally {
        setFetchingData(false);
      }
    };

    fetchBuses();
    fetchDrivers();
  }, []);

  // Handle bus and driver selection
  const handleBusChange = (event) => {
    const busReg = event.target.value;
    setSelectedBus(busReg);
    const bus = busOptions.find(b => b.busRegistration === busReg);
    setSelectedBusDetails(bus);
  };

  const handleDriverChange = (event) => {
    const driverId = event.target.value;
    setSelectedDriver(driverId);
    const driver = driverOptions.find(d => d.idNumber === driverId);
    setSelectedDriverDetails(driver);
  };

  // Generate PDF
  const generatePDF = () => {
    if (!data.length || !selectedBusDetails || !selectedDriverDetails) return;
  
    const doc = new jsPDF();
  
    // Add the company header
    doc.setFontSize(16);
    doc.text('DreamCourier Ltd', 105, 20, { align: 'center' });
    doc.setFontSize(14);
    doc.text('P.O. Box 12345-00100, Nairobi, Kenya', 105, 28, { align: 'center' });
    doc.text('Phone: +254 700 123456', 105, 34, { align: 'center' });
  
    // Add bus details on the left
    doc.setFontSize(13);
    const startXLeft = 14;
    const startY = 30;
    doc.text('Bus Details:', startXLeft, startY);
    doc.text(`Registration: ${selectedBusDetails.busRegistration}`, startXLeft, startY + 6);
    doc.text(`Make: ${selectedBusDetails.make}`, startXLeft, startY + 12);
    doc.text(`Type: ${selectedBusDetails.type}`, startXLeft, startY + 18);
    doc.text(`Ownership: ${selectedBusDetails.ownership}`, startXLeft, startY + 24);
  
    // Add driver details on the right
    const startXRight = 150;
    doc.text('Driver Details:', startXRight, startY);
    doc.text(`Name: ${selectedDriverDetails.firstName} ${selectedDriverDetails.lastName}`, startXRight, startY + 6);
    doc.text(`ID: ${selectedDriverDetails.idNumber}`, startXRight, startY + 12);
    doc.text(`Phone: ${selectedDriverDetails.phoneNumber}`, startXRight, startY + 18);
  
    // Add table with waybill data
    doc.autoTable({
      startY: startY + 30,
      head: [['Origin', 'Destination', 'Parcel Count', 'Total Weight', 'Tracking Codes']], //'Sender Name', 'Receiver Name', 'Sender Phone', 'Receiver Phone'
      body: data.map(item => [
        item.source,
        item.destination,
        item.parcelCount,
        item.totalWeight,
        item.trackingCodes.join(', '),
        // item.senderName,
        // item.receiverName,
        // item.senderPhone,
        // item.receiverPhone
      ]),
      headStyles: { fillColor: [0, 0, 255] }, // Set background color for the header
    });
  
    doc.save('waybill.pdf');
  };

  // Handle pagination change
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handle rows per page change
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
      <Container style={{ backgroundColor: '#f5f5f5' }}>
      <Typography variant="h4" gutterBottom align="center">
        Parcel Waybill
      </Typography>
      <Grid container spacing={2} justifyContent="center" alignItems="center" marginBottom={2}>
        <Grid item xs={12} sm={6} md={4}>
          <TextField
            fullWidth
            label="Date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
            sx={{ maxWidth: 300 }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Select
            fullWidth
            value={route}
            onChange={(e) => setRoute(e.target.value)}
            sx={{ maxWidth: 300 }}
            displayEmpty
          >
            <MenuItem value="1">Nairobi-Wajir</MenuItem>
            <MenuItem value="2">Wajir-Nairobi</MenuItem>
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Select
            fullWidth
            value={selectedBus}
            onChange={handleBusChange}
            displayEmpty
            sx={{ maxWidth: 300 }}
          >
            <MenuItem value="" disabled>Select Bus Registration</MenuItem>
            {busOptions.map((bus) => (
              <MenuItem key={bus.busRegistration} value={bus.busRegistration}>
                {bus.busRegistration} ({bus.make})
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Select
            fullWidth
            value={selectedDriver}
            onChange={handleDriverChange}
            displayEmpty
            sx={{ maxWidth: 300 }}
          >
            <MenuItem value="" disabled>Select Driver</MenuItem>
            {driverOptions.map((driver) => (
              <MenuItem key={driver.idNumber} value={driver.idNumber}>
                {driver.firstName} {driver.lastName}
              </MenuItem>
            ))}
          </Select>
        </Grid>
      </Grid>
      <Grid container justifyContent="center" marginBottom={2}>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Schedule />}
          onClick={fetchWaybillData}
          disabled={loading || fetchingData}
        >
          Fetch Data
        </Button>
      </Grid>
      {loading && (
        <Typography variant="body1" align="center" color="textSecondary">
          Loading data...
        </Typography>
      )}
      {error && (
        <Alert severity="error" align="center">
          {error}
        </Alert>
      )}
      {data.length > 0 && (
        <>
          <TableContainer component={Paper} style={{ marginTop: 20 }}>
            <Table>
              <TableHead style={{ backgroundColor: '#1976d2' }}> 
                <TableRow>
                  <TableCell sx={{ color: '#fff' }}>Origin</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Destination</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Parcel Count</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Weight and Item Count</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Package and Content</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Tracking Codes</TableCell>
                  {/* <TableCell sx={{ color: '#fff' }}>Sender Name</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Receiver Name</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Sender Phone</TableCell>
                  <TableCell sx={{ color: '#fff' }}>Receiver Phone</TableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((item, index) => (
                  <TableRow key={index}>
                    <TableCell>{item.source}</TableCell>
                    <TableCell>{item.destination}</TableCell>
                    <TableCell>{item.parcelCount}</TableCell>
                    <TableCell>{`${item.totalWeight}, ${item.itemsCount}`}</TableCell>
                    <TableCell>{`${item.packageType}, ${item.parcelContent}`}</TableCell>
                    <TableCell>{item.trackingCodes.join(', ')}</TableCell>
                    {/* <TableCell>{item.senderName}</TableCell>
                    <TableCell>{item.receiverName}</TableCell>
                    <TableCell>{item.senderPhone}</TableCell>
                    <TableCell>{item.receiverPhone}</TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={data.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </TableContainer>
          <Grid container justifyContent="center" marginTop={2}>
            <Button
              variant="contained"
              color="secondary"
              onClick={generatePDF}
              disabled={loading || fetchingData}
            >
              Generate PDF
            </Button>
          </Grid>
        </>
      )}
    </Container>
  );
};

export default WaybillComponent;
