import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Button, Paper, Grid } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import QRCode from 'qrcode.react';
import logo from '../../images/home1.png';

const ReprintReceipt = () => {
  const location = useLocation();
  const { parcel } = location.state || {}; // Retrieve the parcel details

  if (!parcel) {
    return <p>No parcel details available.</p>;
  }

  const {
    trackingCode, senderName, senderPhone, receiverName, receiverPhone, recipientAddress,
    parcelContent, packageType, price, itemsCount, source, destination, parcelWeight,
    modeOfPayment, fragile, highValue, perishable, urgent, valueInKsh, serialNumber,
    mpesaAmount, cashAmount, electronics
  } = parcel;

  const username = localStorage.getItem('username');
  const currentDateTime = new Date();
  const formattedDate = currentDateTime.toLocaleDateString();
  const formattedTime = currentDateTime.toLocaleTimeString();

  const formattedSenderPhone = typeof senderPhone === 'string' ? `${senderPhone.slice(0, 4)}xxxx${senderPhone.slice(-2)}` : senderPhone;
  const formattedReceiverPhone = typeof receiverPhone === 'string' ? `${receiverPhone.slice(0, 4)}xxxx${receiverPhone.slice(-2)}` : receiverPhone;

  const handlePrint = () => {
    window.print();
  };

  // Calculate total payment and VAT
  const totalPayment = (mpesaAmount || 0) + (cashAmount || 0);

  // VAT Calculation using the formula (inclusive VAT calculation)
  const baseAmount = (100 * totalPayment) / 116;  // Base amount excluding VAT
  const vatAmount = totalPayment - baseAmount;    // VAT amount

  return (
    <Container maxWidth="sm">
      <style>
        {`
          @media print {
            .no-print {
              display: none;
            }
          }
        `}
      </style>
      <Box
        sx={{
          mt: 1,
          textAlign: 'center',
          border: '1px solid #ddd',
          borderRadius: 1,
          p: 1,
          margin: 1,
          bgcolor: '#fff',
          width: '100%'
        }}
      >
        {/* Logo Section */}
        <Box sx={{ textAlign: 'center', mt: 1 }}>
          <img src={logo} alt="Organization Logo" style={{ width: '120px', marginBottom: '8px' }} />
        </Box>

        {/* Organization and Receipt Title */}
        <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '16px' }}>
          <strong>Dream C Ltd</strong>
        </Typography>
        <Typography variant="h5" component="h1" gutterBottom sx={{ fontSize: '18px' }}>
          <strong>Parcel Bill</strong>
        </Typography>

        {/* Parcel Information */}
        <Typography variant="body2" gutterBottom>
          <strong>{source} <span style={{ margin: '0 4px' }}>→</span> {destination}</strong>
        </Typography>
        <Typography variant="h6" component="h3" gutterBottom sx={{ fontSize: '16px' }}>
          <strong>Tracking No: {trackingCode}/{itemsCount}</strong>
        </Typography>

        {/* Sender and Recipient Information */}
        <Grid container spacing={1} sx={{ mb: 1, justifyContent: 'center' }}>
          <Grid item xs={6} sm={3}>
            <Paper elevation={2} sx={{ p: 1, textAlign: 'center', fontSize: '12px' }}>
              <Typography variant="body1"><strong>Sender:</strong></Typography>
              <Typography variant="body2"><strong>{senderName}</strong></Typography>
              <Typography variant="body2"><strong>{formattedSenderPhone || 'N/A'}</strong></Typography>
            </Paper>
          </Grid>
          <Grid item xs={6} sm={3}>
            <Paper elevation={1} sx={{ p: 1, textAlign: 'center', fontSize: '12px' }}>
              <Typography variant="body1"><strong>Recipient:</strong></Typography>
              <Typography variant="body2"><strong>{receiverName}</strong></Typography>
              <Typography variant="body2"><strong>{formattedReceiverPhone || 'N/A'}</strong></Typography>
            </Paper>
          </Grid>
        </Grid>

        {/* Parcel Details */}
        <Box sx={{ mb: 1, textAlign: 'center' }}>
          <Typography variant="body2" gutterBottom>
            <strong>Address: {recipientAddress}</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>{parcelContent} : Weight: {parcelWeight} kg</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Packaging: {packageType}</strong>
          </Typography>
        </Box>

        {/* Payment Information */}
        <Box sx={{ mb: 1, textAlign: 'center' }}>
          <Typography variant="body2" gutterBottom>
            <strong>Total Amount Paid: Ksh {totalPayment.toFixed(2)}</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>Amount (Excl. VAT): Ksh {baseAmount.toFixed(2)}</strong>
          </Typography>
          <Typography variant="body2" gutterBottom>
            <strong>VAT (16%): Ksh {vatAmount.toFixed(2)}</strong>
          </Typography>
        </Box>

        {/* Display QR Code */}
        <Box sx={{ mb: 1, textAlign: 'center' }}>
          <QRCode value={trackingCode} size={60} />
        </Box>

        {/* Notice Sections */}
        <Box sx={{ mt: 1, p: 1, backgroundColor: '#fdd835', borderRadius: 1 }}>
          <Typography variant="body2" color="error" align="center">
            All second hand goods have no compensation.
          </Typography>
        </Box>
        <Box sx={{ mt: 1, p: 1, backgroundColor: '#e0f7fa', borderRadius: 1 }}>
          <Typography variant="body2" align="center">
            The sender hereby accepts terms and conditions of travel or acceptance of parcels having read
            the terms and conditions displayed at all our offices and our website.
          </Typography>
        </Box>
        <Box sx={{ mt: 1, p: 1, backgroundColor: '#ffebee', borderRadius: 1 }}>
          <Typography variant="body2" align="center">
            The company is not responsible for delay or mis-delivery due to wrong address or detention by
            government authorities.
          </Typography>
        </Box>

        <Box sx={{ mt: 1, textAlign: 'center' }}>
          <Button variant="contained" color="primary" onClick={handlePrint}>
            Print Receipt
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default ReprintReceipt;
