import React, { useState } from 'react';
import {
  Modal,
  Box,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  CircularProgress
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axiosInstance from '../Admin/axiosInstance';

const ParcelReturnModal = ({ open, onClose, parcelsReadyForReturn }) => {
  const [selectedParcel, setSelectedParcel] = useState(null);
  const [trackingCode, setTrackingCode] = useState('');
  const [modeOfPayment, setModeOfPayment] = useState('cash');
  const [mpesaNumber, setMpesaNumber] = useState('');
  const [cashAmount, setCashAmount] = useState(0);
  const [mpesaAmount, setMpesaAmount] = useState(0);
  const [idNumber, setIdNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [paymentPending, setPaymentPending] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  // Payment Options
  const paymentOptions = [
    { id: 'Cash', label: 'Cash' },
    { id: 'Mpesa', label: 'Mpesa' },
    { id: 'cash + mpesa', label: 'Mpesa and Cash' },
    { id: 'foc', label: 'FOC' },
    { id: 'AirtelMoney', label: 'Airtel Money' },
  ];

  // Reset Form
  const resetForm = () => {
    setSelectedParcel(null);
    setTrackingCode('');
    setModeOfPayment('cash');
    setMpesaNumber('');
    setCashAmount(0);
    setMpesaAmount(0);
    setIdNumber('');
    setPhoneNumber('');
    setFirstName('');
    setLastName('');
    setPaymentPending(false);
    setSuccessMessage('');
    setErrorMessage('');
  };

  // Function to monitor the payment status
  const checkPaymentStatus = async (trackingCode) => {
    try {
      let attempts = 0;
      return new Promise((resolve, reject) => {
        const intervalId = setInterval(async () => {
          attempts += 1;
          const response = await axiosInstance.get(`/parcel/paidstatus/${trackingCode}`);
  
          if (response.data === true) {
            clearInterval(intervalId);
            setPaymentPending(false);
            setSuccessMessage('Payment successful!');
            resolve(true); // Resolve the promise on successful payment
          } else if (attempts >= 5) {
            clearInterval(intervalId);
            setPaymentPending(false);
            setErrorMessage('Payment failed after multiple attempts. Please try again.');
            reject(new Error('Payment failed')); // Reject the promise after 5 attempts
          }
        }, 5000);
      });
    } catch (error) {
      setErrorMessage('Error checking payment status.');
      throw error; // Throw error to be caught in the calling function
    }
  };
  
  const handleResendStkPush = async () => {
    try {
      await axiosInstance.post(`/parcel/stkpush/resend?parcelId=${selectedParcel.id}`);
      setSuccessMessage('STK Push Resent! Please complete the payment on your phone.');
    } catch (error) {
      setErrorMessage('Error resending STK Push.');
    }
  };
  
  const handleReturnSubmit = async (event) => {
    event.preventDefault();
    setPaymentPending(true);
  
    // Set the tracking code before making requests
    const generatedTrackingCode = generateTrackingCode();
    setTrackingCode(generatedTrackingCode); // Optionally store this
  
    // Prepare data for both endpoints
    const parcelReturnData = {
      trackingCode: generatedTrackingCode,
      modeOfPayment,
      mpesaNumber,
      cashAmount,
      mpesaAmount,
    };
  
    const parcelReceiverData = {
      idNumber,
      phoneNumber,
      trackingCode: generatedTrackingCode,
      firstName,
      lastName,
    };
  
    try {
      // Make the first request to the parcel return endpoint
      const parcelReturnResponse = await axiosInstance.post(
        `/parcel/returned/${selectedParcel.id}`,
        parcelReturnData
      );
  
      // If the first request is successful, handle the payment status check
      if (parcelReturnResponse.status === 200) {
        // Check if the payment involves Mpesa
        if (modeOfPayment === 'mpesa' || modeOfPayment === 'mpesa + cash') {
          try {
            // Wait for the payment status confirmation
            const paymentSuccess = await checkPaymentStatus(generatedTrackingCode);
            if (paymentSuccess) {
              // Proceed to add the receiver details if payment is successful
              const parcelReceiverResponse = await axiosInstance.post(
                `/parcelreceiver/add/${selectedParcel.id}`,
                parcelReceiverData
              );
  
              if (parcelReceiverResponse.status === 200) {
                setSuccessMessage('Parcel successfully returned and receiver details added!');
                setErrorMessage('');
              } else {
                setErrorMessage('Failed to add receiver details. Please try again.');
                setSuccessMessage('');
              }
            }
          } catch (error) {
            // Payment check failed, offer the option to resend STK push
            setErrorMessage('Payment failed. Please resend STK Push.');
            setSuccessMessage('');
          }
        } else {
          // For non-Mpesa payments, directly add receiver details
          const parcelReceiverResponse = await axiosInstance.post(
            `/parcelreceiver/add/${selectedParcel.id}`,
            parcelReceiverData
          );
  
          if (parcelReceiverResponse.status === 200) {
            setSuccessMessage('Parcel successfully returned and receiver details added!');
            setErrorMessage('');
          } else {
            setErrorMessage('Failed to add receiver details. Please try again.');
            setSuccessMessage('');
          }
        }
      } else {
        setErrorMessage('Failed to return the parcel. Please try again.');
        setSuccessMessage('');
      }
    } catch (error) {
      setErrorMessage('Error occurred while returning the parcel and adding receiver details.');
      console.error('Error:', error);
    } finally {
      setPaymentPending(false);
    }
  };
  
  

  const generateTrackingCode = () => {
    const characters = '0123456789';
    let trackingCode = '';
    for (let i = 0; i < 12; i++) {
      trackingCode += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return trackingCode;
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '10%',
          left: '50%',
          transform: 'translate(-50%, 0)',
          width: '60%',
          maxHeight: '80vh',
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 24,
          p: 4,
          overflowY: 'auto',
        }}
      >
        <h2>Parcels for Return</h2>

        {/* Conditionally render the parcels list if no parcel is selected */}
        {!selectedParcel && parcelsReadyForReturn.length > 0 ? (
          parcelsReadyForReturn.map((parcel) => (
            <div key={parcel.id} style={{ marginBottom: '8px' }}>
              <p>Tracking Code: {parcel.trackingCode} Sender Name: {parcel.senderName} Sender Phone: {parcel.senderPhone}</p>
              <Button variant="contained" size="small" onClick={() => setSelectedParcel(parcel)}>
                Select
              </Button>
            </div>
          ))
        ) : !selectedParcel ? (
          <p>No parcels available for return.</p>
        ) : null}

        {/* Conditionally render the form only when a parcel is selected */}
        {!paymentPending && selectedParcel && (
          <form onSubmit={handleReturnSubmit}>
            <h3>Return Details for {selectedParcel.trackingCode}</h3>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="ID Number"
                  value={idNumber}
                  onChange={(e) => setIdNumber(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Phone Number"
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="First Name"
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="Last Name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                  fullWidth
                  required
                />
              </Grid>

              {/* Payment Mode Selection */}
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required>
                  <InputLabel id="payment-label">Mode of Payment</InputLabel>
                  <Select
                    labelId="payment-label"
                    name="modeOfPayment"
                    value={modeOfPayment}
                    onChange={(e) => setModeOfPayment(e.target.value)}
                    label="Mode of Payment"
                  >
                    {paymentOptions.map((option) => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              {/* Input Fields Based on Payment Method */}
              {modeOfPayment === 'Mpesa' && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Enter Mpesa Number"
                    value={mpesaNumber}
                    onChange={(e) => setMpesaNumber(e.target.value)}
                    fullWidth
                  />
                </Grid>
              )}

              {/* Price Fields */}
              {(modeOfPayment === 'Cash' || modeOfPayment === 'Mpesa') && (
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Amount"
                    type="number"
                    value={modeOfPayment === 'Cash' ? cashAmount : mpesaAmount}
                    onChange={(e) =>
                      modeOfPayment === 'Cash' ? setCashAmount(e.target.value) : setMpesaAmount(e.target.value)
                    }
                    fullWidth
                  />
                </Grid>
              )}

              {/* Mixed Payment Fields */}
              {modeOfPayment === 'cash + mpesa' && (
                <>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Enter Mpesa Number"
                      value={mpesaNumber}
                      onChange={(e) => setMpesaNumber(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Mpesa Amount"
                      type="number"
                      value={mpesaAmount}
                      onChange={(e) => setMpesaAmount(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <TextField
                      label="Cash Amount"
                      type="number"
                      value={cashAmount}
                      onChange={(e) => setCashAmount(e.target.value)}
                      fullWidth
                    />
                  </Grid>
                </>
              )}
            </Grid>

            {/* Submit Button */}
            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2 }}>
              Submit Return
            </Button>
          </form>
        )}

        {/* Show Payment Progress Screen */}
        {paymentPending && (
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
            <CircularProgress />
            <p style={{ marginLeft: '8px' }}>Checking payment status...</p>
          </Box>
        )}

        {/* Success or Error Message */}
        {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
        {errorMessage && (
          <Box>
            <p style={{ color: 'red' }}>{errorMessage}</p>
            <Button onClick={handleResendStkPush}>Resend STK Push</Button>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

export default ParcelReturnModal;