import React from 'react';
import { useLocation } from 'react-router-dom';
import Navbar from './Navbar';
import { Outlet } from 'react-router-dom';

const Layout = ({ user, handleLogout }) => {
  const location = useLocation();

  // Hide Navbar on the admin routes and the login page
  const showNavbar = !location.pathname.startsWith('/admin') && location.pathname !== '/login' && location.pathname !== '/'
  && location.pathname !== '/reset-password' && location.pathname !=='/ticket-details/:ticketNumber';

  return (
    <div className="App">
      {showNavbar && <Navbar user={user.username} onLogout={handleLogout} />}
      <Outlet />
    </div>
  );
};

export default Layout;
