import React from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Typography, Box, Button, Paper, Grid } from '@mui/material';
import AccountBalanceWalletIcon from '@mui/icons-material/AccountBalanceWallet';
import MobileScreenShareIcon from '@mui/icons-material/MobileScreenShare';
import QRCode from 'qrcode.react';
import logo from '../../images/home1.png';

const ReceiptPage = () => {
    const location = useLocation();
    const {
        trackingCode, senderName, senderPhone, receiverName, receiverPhone, recipientAddress,
        parcelContent, packageType, price, itemsCount, source, destination, parcelWeight,
        modeOfPayment, fragile, highValue, perishable, urgent, valueInKsh, serialNumber,
        mpesaAmount, cashAmount, electronics
    } = location.state || {};

    const username = localStorage.getItem('username');

    const handlePrint = () => {
        window.print();
    };

    const currentDateTime = new Date();
    const formattedDate = currentDateTime.toLocaleDateString();
    const formattedTime = currentDateTime.toLocaleTimeString();

    // Calculate VAT using the provided formula
    const calculateVAT = (totalPayment) => {
        const baseAmount = totalPayment / 1.16;
        const vatAmount = totalPayment - baseAmount;
        return {
            baseAmount: baseAmount.toFixed(2),
            vatAmount: vatAmount.toFixed(2),
            totalAmount: totalPayment.toFixed(2),
        };
    };

    // Calculate VAT for cash and mpesa amounts
    const mpesaDetails = mpesaAmount ? calculateVAT(parseFloat(mpesaAmount)) : null;
    const cashDetails = cashAmount ? calculateVAT(parseFloat(cashAmount)) : null;

     // Sum of VAT amounts for Cash + Mpesa payment mode
    const combinedVAT = mpesaDetails && cashDetails ? (parseFloat(mpesaDetails.vatAmount) + parseFloat(cashDetails.vatAmount)).toFixed(2) : '0.00';

    return (
        <Container maxWidth="sm">
            <style>
                {`
                @media print {
                    .no-print {
                        display: none;
                    }
                }
                `}
            </style>
            <Box
                sx={{
                    mt: 1,
                    textAlign: 'center',
                    border: '1px solid #ddd',
                    borderRadius: 1,
                    p: 1,
                    margin: 1,
                    bgcolor: '#fff',
                    width: '100%',
                }}
            >
                <Box sx={{ textAlign: 'center', mt: 1 }}>
                    <img src={logo} alt="Organization Logo" style={{ width: '120px', marginBottom: '8px' }} />
                </Box>
                <Typography variant="h6" component="h2" gutterBottom sx={{ fontSize: '16px' }}>
                    <strong>Dream C Ltd</strong>
                </Typography>
                <Typography variant="h5" component="h1" gutterBottom sx={{ fontSize: '18px' }}>
                    <strong>Parcel Bill</strong>
                </Typography>

                <Typography variant="body2" gutterBottom>
                    <strong>{source} <span style={{ margin: '0 4px' }}>→</span> {destination}</strong>
                </Typography>
                <Typography variant="h6" component="h3" gutterBottom sx={{ fontSize: '16px' }}>
                    <strong>Tracking No: {trackingCode}/{itemsCount}</strong>
                </Typography>

                <Grid container spacing={1} sx={{ mb: 1, justifyContent: 'center' }}>
                    <Grid item xs={6} sm={3}>
                        <Paper elevation={2} sx={{ p: 1, textAlign: 'center', fontSize: '12px' }}>
                            <Typography variant="body1"><strong>Sender:</strong></Typography>
                            <Typography variant="body2"><strong>{senderName}</strong></Typography>
                            <Typography variant="body2">
                                <strong>{`${senderPhone.slice(0, 4)}xxxx${senderPhone.slice(-2)}`}</strong>
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item xs={6} sm={3}>
                        <Paper elevation={1} sx={{ p: 1, textAlign: 'center', fontSize: '12px' }}>
                            <Typography variant="body1"><strong>Recipient:</strong></Typography>
                            <Typography variant="body2"><strong>{receiverName}</strong></Typography>
                            <Typography variant="body2">
                                <strong>{`${receiverPhone.slice(0, 4)}xxxx${receiverPhone.slice(-2)}`}</strong>
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>

                <Box sx={{ mb: 1, textAlign: 'center' }}>
                    <Typography variant="body2" gutterBottom>
                        <strong>Address: {recipientAddress}</strong>
                    </Typography>
                    <Typography variant="body2" gutterBottom>
                        <strong>{parcelContent} : Weight: {parcelWeight} kg  Packaging: {packageType}</strong>
                    </Typography>
                </Box>

                {fragile && (
                    <Typography variant="body2" gutterBottom>
                        <strong>Fragile:</strong> Yes
                    </Typography>
                )}
                {perishable && (
                    <Typography variant="body2" gutterBottom>
                        <strong>Perishable:</strong> Yes
                    </Typography>
                )}
                {urgent && (
                    <Typography variant="body2" gutterBottom>
                        <strong>Urgent:</strong> Yes
                    </Typography>
                )}
                {highValue && (
                    <>
                        <Typography variant="body2" gutterBottom>
                            <strong>High Value:</strong> Yes  <strong>Value Ksh:</strong> {valueInKsh}
                        </Typography>
                    </>
                )}
                {electronics && (
                    <>
                        <Typography variant="body2" gutterBottom>
                            <strong>ELECTRONICS:</strong> Yes
                        </Typography>
                        <Typography variant="body2" gutterBottom>
                            <strong>Product Serial:</strong> {serialNumber}
                        </Typography>
                    </>
                )}


                       {/* Payment Mode Display */}
                       <Box sx={{ mb: 1, textAlign: 'center' }}>
                        <Typography variant="body2" gutterBottom>
                            {modeOfPayment === 'Mpesa' && mpesaDetails && (
                                <>
                                    <MobileScreenShareIcon sx={{ verticalAlign: 'middle' }} /> 
                                    <strong>Mpesa Amount: </strong>Ksh {mpesaDetails.totalAmount} 
                                    <br />
                                    <strong>Amount: </strong>Ksh {mpesaDetails.baseAmount} 
                                    <br />
                                    <strong>VAT: </strong>Ksh {mpesaDetails.vatAmount}
                                </>
                            )}
                            {modeOfPayment === 'Cash' && cashDetails && (
                                <>
                                    <AccountBalanceWalletIcon sx={{ verticalAlign: 'middle' }} /> 
                                    <strong>Cash Amount: </strong>Ksh {cashDetails.totalAmount} 
                                    <br />
                                    <strong>Amount: </strong>Ksh {cashDetails.baseAmount} 
                                    <br />
                                    <strong>VAT: </strong>Ksh {cashDetails.vatAmount}
                                </>
                            )}
                            {modeOfPayment === 'cash + mpesa' && mpesaDetails && cashDetails && (
                                <>
                                    <MobileScreenShareIcon sx={{ verticalAlign: 'middle' }} /> 
                                    <strong>Mpesa Amount: </strong>Ksh {mpesaDetails.totalAmount} &nbsp;
                                    <AccountBalanceWalletIcon sx={{ verticalAlign: 'middle' }} /> 
                                    <strong>Cash Amount: </strong>Ksh {cashDetails.totalAmount} 
                                    <br />
                                    <strong>Total Amount: </strong>Ksh {(parseFloat(mpesaDetails.baseAmount) + parseFloat(cashDetails.baseAmount)).toFixed(2)} 
                                    <br />
                                    <strong>Total VAT: </strong>Ksh {combinedVAT}
                                </>
                            )}
                            {modeOfPayment === 'foc' && (
                                <span>Fully Out of Charge (FOC)</span>
                            )}
                        </Typography>
                    </Box>

                <Box sx={{ textAlign: 'center', mt: 0, mb: 1 }}>
                    <QRCode value={trackingCode} size={60} />
                </Box>

                {/* Notice Sections */}
                <Box sx={{ mt: 1, p: 1, backgroundColor: '#fdd835', borderRadius: 1 }}>
                <Typography variant="body2" color="error" align="center">
                    All second hand goods have no compensation.
                </Typography>
                </Box>
                <Box sx={{ mt: 1, p: 1, backgroundColor: '#e0f7fa', borderRadius: 1 }}>
                <Typography variant="body2" align="center">
                    The sender hereby accepts terms and conditions of travel or acceptance of parcels having read
                    the terms and conditions displayed at all our offices and our website.
                </Typography>
                </Box>
                <Box sx={{ mt: 1, p: 1, backgroundColor: '#ffebee', borderRadius: 1 }}>
                <Typography variant="body2" align="center">
                    The company is not responsible for delay or mis-delivery due to wrong address or detention by
                    government authorities.
                </Typography>
                </Box>

                <Box sx={{ mt: 1, textAlign: 'center' }}>
                    <Typography variant="body2" gutterBottom>
                        Booked By: {username} at {formattedDate} {formattedTime}
                    </Typography>
                </Box>

                <Button
                    onClick={handlePrint}
                    variant="contained"
                    color="primary"
                    className="no-print"
                >
                    Print Receipt
                </Button>
            </Box>
        </Container>
    );
};

export default ReceiptPage;
