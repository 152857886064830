import React, { useEffect, useState } from 'react';
import {
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Radio,
} from '@mui/material';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import axiosInstance from './axiosInstance';

const CorporateManager = () => {
  const [corporates, setCorporates] = useState([]);
  const [newCorporateName, setNewCorporateName] = useState('');
  const [email, setEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [contactPerson, setContactPerson] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedCorporate, setSelectedCorporate] = useState('');
  const [invoices, setInvoices] = useState([]); // State to hold invoice data

  // Fetch all corporates
  useEffect(() => {
    fetchCorporates();
  }, []);

  const fetchCorporates = async () => {
    try {
      const response = await axiosInstance.get('/customer/findall');
      setCorporates(response.data);
    } catch (error) {
      console.error('Error fetching corporates:', error);
    }
  };

  const handleAddCorporate = async () => {
    if (!newCorporateName || !email || !phoneNumber || !contactPerson) return;
  
    try {
      await axiosInstance.post('/customer/add', {
        name: newCorporateName,
        email,
        phoneNumber,
        contactPerson
      });
      // Reset fields after successful addition
      setNewCorporateName('');
      setEmail('');
      setPhoneNumber('');
      setContactPerson('');
      fetchCorporates(); // Refresh the list after adding
      setOpenDialog(false); // Close the dialog
    } catch (error) {
      console.error('Error adding corporate:', error);
    }
  };
  

  const handleFetchInvoices = async () => {
    if (!selectedCorporate || !startDate || !endDate) {
      alert('Please select a corporate name and specify a date range.');
      return;
    }

    try {
      const invoiceData = await axiosInstance.get(`/parcel/invoice/${selectedCorporate}/${startDate}/${endDate}`);
      setInvoices(invoiceData.data); // Store invoice data in state
      console.log('Invoices:', invoiceData.data);
    } catch (error) {
      console.error('Error fetching invoices:', error);
    }
  };

  // Calculate the total amount for the invoices
  const totalAmount = invoices.reduce((total, invoice) => total + (invoice.cashAmount || 0), 0);

  const downloadPDF = () => {
    const doc = new jsPDF();
  
    // Organization Name
    const organizationName = 'DreamCourier Ltd';
    doc.setFontSize(14); 
    const organization = 'P.O. Box 12345-00100, Nairobi, Kenya';
    doc.setFontSize(12);
    // Set font size for the organization name DreamCourier Ltd
    
   
    doc.text(organizationName, 105, 5, null, null, 'center'); 
    doc.text(organization, 105, 10, null, null, 'center');
  
    // Corporate Name
    doc.setFontSize(12); // Set smaller font size for the rest of the text
    doc.text(`Corporate Name: ${selectedCorporate}`, 14, 20);
  
    const tableColumn = [
      'Source', 'Destination', 'Sender Name', 'Receiver Name',
      'Sender Phone', 'Receiver Phone', 'Book Date', 'Weight', 'Tracking Code', 'Items Count', 'Package Type', 'Amount'
    ];
    const tableRows = [];
  
    let totalAmount = 0; // Variable to hold the total amount
  
    // Loop through the invoices and add them to the PDF
    invoices.forEach((invoice) => {
      const invoiceData = [
        invoice.source,
        invoice.destination,
        invoice.senderName,
        invoice.receiverName,
        invoice.senderPhone,
        invoice.receiverPhone,
        invoice.bookDate,
        invoice.parcelWeight,
        invoice.trackingCode,
        invoice.itemsCount,
        invoice.packageType,
        invoice.cashAmount,
      ];
      totalAmount += parseFloat(invoice.cashAmount); // Calculate total amount
      tableRows.push(invoiceData);
    });
  
    // Add a total row at the bottom of the table
    const totalRow = ['', '', '', '', '', '', '', '', '', '', 'Total Amount', totalAmount.toFixed(2)];
    tableRows.push(totalRow);
  
    doc.autoTable({
      head: [tableColumn],
      body: tableRows,
      startY: 30, // Start the table a little lower
      styles: {
        fontSize: 10, // Reduce font size in the table
      },
    });
  
    doc.save(`${selectedCorporate}_Invoices.pdf`);
  };
  
  return (
    <Box sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Corporate Management
      </Typography>
      <Button variant="contained" onClick={() => setOpenDialog(true)}>
        Add Corporate
      </Button>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>Add Corporate</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Corporate Name"
            type="text"
            fullWidth
            variant="outlined"
            value={newCorporateName}
            onChange={(e) => setNewCorporateName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Email"
            type="email"
            fullWidth
            variant="outlined"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Phone Number"
            type="tel"
            fullWidth
            variant="outlined"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Contact Person"
            type="text"
            fullWidth
            variant="outlined"
            value={contactPerson}
            onChange={(e) => setContactPerson(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)}>Cancel</Button>
          <Button onClick={handleAddCorporate}>Add</Button>
        </DialogActions>
      </Dialog>


      <Box sx={{ my: 2 }}>
        <TextField
          label="Start Date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          label="End Date"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
      </Box>

      <Box sx={{ mb: 2 }}>
        <Button variant="outlined" onClick={handleFetchInvoices}>
          Fetch Invoices
        </Button>
        <Button variant="outlined" onClick={downloadPDF} disabled={!invoices.length}>
          Download PDF
        </Button>
      </Box>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Select</TableCell>
              <TableCell>Corporate Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone Number</TableCell>
              <TableCell>Contact Person</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {corporates.map((corporate) => (
              <TableRow key={corporate.id}>
                <TableCell>
                  <Radio
                    checked={selectedCorporate === corporate.name}
                    onChange={() => setSelectedCorporate(corporate.name)}
                  />
                </TableCell>
                <TableCell>{corporate.name}</TableCell>
                <TableCell>{corporate.email}</TableCell>
                <TableCell>{corporate.phoneNumber}</TableCell>
                <TableCell>{corporate.contactPerson}</TableCell>
                <TableCell>
                  <Button variant="outlined" onClick={() => handleFetchInvoices(corporate.name)}>
                    View Invoices
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {invoices.length > 0 && (
        <Box sx={{ mt: 2 }}>
          <Typography variant="h6">Invoice List for {selectedCorporate}:</Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Source</TableCell>
                  <TableCell>Destination</TableCell>
                  <TableCell>Sender Name</TableCell>
                  <TableCell>Receiver Name</TableCell>
                  <TableCell>Amount</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {invoices.map((invoice) => (
                  <TableRow key={invoice.id}>
                    <TableCell>{invoice.source}</TableCell>
                    <TableCell>{invoice.destination}</TableCell>
                    <TableCell>{invoice.senderName}</TableCell>
                    <TableCell>{invoice.receiverName}</TableCell>
                    <TableCell>{invoice.cashAmount}</TableCell>
                  </TableRow>
                ))}
                {/* Total Amount Row */}
                <TableRow>
                  <TableCell colSpan={4} align="right">
                    <strong>Total Amount</strong>
                  </TableCell>
                  <TableCell>
                    <strong>{totalAmount}</strong>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}
    </Box>
  );
};

export default CorporateManager;